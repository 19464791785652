import { Header } from '../partials/Header';
import { Hero } from '../components/Hero';
import { Solutions } from '../components/Solutions';
import { Portfolio } from '../components/Portfolio';
import { Footer } from '../partials/Footer';

export function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Solutions />
      <Portfolio />
      <Footer />
    </>
  );
}