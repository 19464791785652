import { useEffect, useState } from "react";

import { CardPortfolio, CardProps } from "../CardPortfolio";

import { Container } from "./styles";

export function Portfolio() {
  const [portfolio, setPortfolio] = useState<CardProps[]>([]);

  useEffect(() => {
    fetch('./portfolio.json', {
      headers: {
        Accept: "application/json"
      }
      }).then(res => res.json())
        .then(res => setPortfolio(res.data))
  }, []);

  // portfolio.sort((a, b) => {
  //   if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //     return -1;
  //   }
  //   if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //     return 1;
  //   }
  //   return 0;
  // });

  return (
    <Container id="portfolio">
      <header className="major">
        <h2>Portfólio</h2>
        <p>Alguns projetos realizados</p>
      </header>

      <main>

        {portfolio.map(item => {
          return (
            <CardPortfolio
              key={item.name} 
              name={item.name}
              img={item.img} 
              url={item.url}
              flags={item.flags.map(flag => flag)}
            />
          );
        })}

      </main>
    </Container>
  );
}