import styled from "styled-components";

export const Container = styled.header`
  background-color: var(--bg);
	height: 6rem;

  div {
    max-width: 1120px;
    margin: 0 auto;
		padding: 1rem;

		display: flex;
    justify-content: space-between;
    align-items: center;
  }

	h1 {
		img {
			display: block;
			width: 60px;
			height: 65px;
		}
	}

  nav {
		ul {
			list-style: none;

			li {
				display: inline-block;

				& + li {
					margin-left: 2rem;
				}

				a {
					font-size: 1.1rem;
					text-decoration: none;
					color: #eee;
					
					transition: color 0.2s ease-in-out;

					&::after {
						border-bottom: 3px solid var(--green);
						content: '';
						display: block;
						opacity: 0;
						
						position: relative;
						top: 1rem;

						transition: all 0.2s ease-in-out;
					}

					&:hover {
						color: var(--green);

						&::after {
							top: 0;
							opacity: 1;
						}
					}
				}
			}
		}
  }

	@media (max-width: 425px) {
    nav {
      display: none;
    }
  }
`;