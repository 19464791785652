import styled from "styled-components";

import logoImg from '../../assets/logo.svg';

export const Container = styled.footer`
  background: var(--text-background);
	padding: 6rem 0;
	text-align: center;
  line-height: 1.8rem;

  .logo {
    width: 100px;
    height: 108px;
    background-image: url(${logoImg});
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 auto 1em;
  }

  a {
    transition: color 0.2s ease-in-out;
    text-decoration: none;

    &:active, &:hover {
      color: var(--green);
    }
  }

  .copyright {
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;