import { GoLinkExternal } from 'react-icons/go';

import { Container, Content } from './styles';

export type CardProps = {
  img: string;
  name: string;
  url: string;
  flags: Array<string>;
}

export function CardPortfolio({img, name, url, flags}: CardProps) {
  return (
    <Container>
      <Content>
        <img src={img} alt={name} />
        <div className="image-caption">
          <div className="inner">
            <h3>{name}</h3>
            <p>{flags.map((flag) => <span key={flag} className="flag">{flag}</span>)}</p>
            {url && <a href={url} target="_blank" rel="noreferrer"><GoLinkExternal /> Conhecer o projeto</a>}
          </div>
        </div>
      </Content>
    </Container>
  );
}