import styled from "styled-components";

export const Container = styled.div`
  flex: 1 50%;

  @media (max-width: 768px) {
    flex: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  display: block;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(2) rotate(5deg);
    }

    .image-caption {
      display: block;
      opacity: 1;
    }
  }

  img {
    width: 100%;
    transform: scale(1.5) rotate(10deg);
    transition: transform 0.5s ease;
  }

  .image-caption {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: var(--bg);
    color: var(--shape);
    transition: opacity 0.5s ease;

    &:hover {
      h3, p, a {
        transform: translateY(0);
      }
    }

    @media (max-width: 768px) {
      opacity: 1;
      background: rgb(0 0 0 / 0.5);
    }

    .inner {
      width: 100%;
      height: 100%;
      margin: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    h3 {
      font-size: 2rem;
      color: var(--shape);
      margin-bottom: 1rem;
    }

    h3, p {
      transform: translateY(-50%);
      transition: transform 0.2s linear;

      @media (max-width: 425px) {
        transform: translateY(0);
      }
    }

    .flag {
      border: 0.1rem dashed var(--green);
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      display: inline-block;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: var(--shape);

      @media (max-width: 425px) {
        padding: 0.5rem;
      }

      @media (max-width: 375px) {
        font-size: 0.65rem;
        padding: 0.3rem;
        margin: 0.3rem;
      }
    }

    a {
      background-color: var(--bg);
      color: var(--shape);
      text-decoration: none;
      text-align: center;
      font-size: 0.9rem;
      display: inline-block;
      padding: 0.5rem 1rem;
      margin: 1rem;
      border: 0.1rem solid var(--shape);
      border-radius: 0.3rem;

      transform: translateY(50%);
      transition: transform 0.2s linear;

      &:hover {
        background: var(--green);
        color: var(--text-title);
        border-color: var(--green);
        
        svg {
          fill: var(--text-title);
        }
      }

      @media (max-width: 425px) {
        transform: translateY(0);
      }
    }

    svg {
      fill: var(--shape);
      margin-right: 0.3rem;
      transition: all 0.2s linear;
    }
  }
`;