import styled from "styled-components";

export const Container = styled.section`
  background: #eee;
  text-align: center;

  header {
    margin: 0 0 5rem 0;

    h2 {
      font-size: 3rem;
      display: inline-block;
      margin: 4rem 0 0;
    }

    p {
      font-size: 1.7rem;
    }
  }

  main {
    display: flex;
    flex-wrap: wrap;
  }
`;