import styled from 'styled-components';

import bgImg from '../../assets/banner.jpg';

export const Container = styled.section`
  background: var(--bg);

  background-attachment: fixed;
	background-image: url(${bgImg});
	background-position: center top;
	background-size: cover;
	line-height: 1.75;
	text-align: center;

  > div {
    background-color: var(--bg);
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-size: 4rem;
    color: var(--shape);

    @media (max-width: 425px) {
      font-size: 3rem;
    }
  }
  
  p {
    font-size: 1.6rem;
    color: var(--text-background);

    @media (max-width: 425px) {
      font-size: 1.3rem;
    }

    @media (max-width: 320px) {
      font-size: 1.1rem;
      margin: 2rem;
    }
  }

  a {
    border: 3px solid var(--green);
    font-size: 1rem;
    color: var(--shape);
    text-decoration: none;
    text-transform: uppercase;
    background-color: var(--green);
    display: inline-block;

    padding: 1rem 3rem;
    border-radius: 0.25rem;
    font-weight: 700;
    margin: 2rem 0;

    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: transparent;
      color: var(--green);
    }

    @media (max-width: 425px) {
      margin: 2rem;
    }
  }

  svg {
    font-size: 1.5rem;
    fill: var(--text-background);

    position: relative;
    top: 0px;
    animation: anima 1.5s infinite;

    @keyframes anima {
      from {
        top: 0px;
        opacity: 1;
      }
      to {
        top: 20px;
        opacity: 0;
      }
    }
  }
`;