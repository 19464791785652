import { SiWordpress } from 'react-icons/si';
import { FiShoppingBag } from 'react-icons/fi';
import { FaLaptopCode } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';

import { CardSolutions } from '../CardSolutions';

import { Container } from "./styles";

export function Solutions() {
  return (
    <Container id="solucoes">
      <div>

        <CardSolutions 
          icon={FaLaptopCode} 
          title="Websites" 
          text="Sites com código limpo, com foco na performance, utilizando as tecnologias mais atuais." 
        />

        <CardSolutions 
          icon={FiShoppingBag} 
          title="E-commerce" 
          text="Desenvolvimento de lojas virtuais nas plataformas Vtex IO, Linx Commerce e WooCommerce." 
        />

        <CardSolutions 
          icon={RiPagesLine} 
          title="Landing Pages" 
          text="Criação e desenvolvimento de páginas promocionais e de conversão." 
        />

        <CardSolutions 
          icon={SiWordpress} 
          title="Wordpress" 
          text="Atualização, manutenção e otimização de sites gerenciáveis, desenvolvidos com Wordpress." 
        />

      </div>
    </Container>
  )
}