import styled from "styled-components";

export const Container = styled.section`
  > div {
    max-width: 760px;
    margin: 0 auto;
    padding: 4rem 2rem;

    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
`;