import { ComponentType } from 'react';

import { Container } from './styles';

interface CardProps {
  icon: ComponentType;
  title: string;
  text: string;
}

export function CardSolutions({icon: Icon, title, text}: CardProps) {
  return (
    <Container>
      <i><Icon /></i>
      <div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </Container>
  );
}