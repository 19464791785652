import { Container } from "./styles";

export function Footer() {
  return(
    <Container id="footer">
      <div className="logo"></div>
      <a href="mailto:contato@felipedesigner.com.br">contato@felipedesigner.com.br</a>
      <div className="copyright">
        <div>Copyright &copy; {new Date().getFullYear()}. Todos os direitos reservados.</div>
        <div>Porto Alegre - RS - Brasil</div>
      </div>
    </Container>
  )
}