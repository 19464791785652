import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 375px) {
    flex: 100%;
    flex-direction: column;
    text-align: center;
  }

  i {
    font-size: 5rem;
    width: 7.5rem;
    height: 7.5rem;
    background-color: var(--text-background);
    line-height: 7.5rem;
    border-radius: 100%;
    margin-right: 1rem;

    flex-basis: auto;
    flex-shrink: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-in-out;

    @media (max-width: 375px) {
      margin: 0 0 1rem 0;
    }
  }

  p {
    @media (max-width: 375px) {
      margin: 0 0 1rem 0;
      line-height: 1.5rem;
    }
  }

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    cursor: default;

    i {
      background-color: var(--green);
      box-shadow: 0 3px 10px 1px #ccc;
    }

    svg {
      transform: scale(0.8);
    }

    h3 {
      color: var(--green);
    }
  }
`;