import AnchorLink from 'react-anchor-link-smooth-scroll';
import { BsArrowDown } from 'react-icons/bs';

import { Container } from "./styles";

export function Hero() {
  return (
    <Container id="hero">
      <div>
        <h2>Oi, tudo bem?</h2>
        <p>Meu nome é Felipe, sou publicitário e desenvolvedor front-end.<br />
        Atualmente atuo como freelancer.</p>
        <AnchorLink href="#portfolio">Veja alguns dos meus últimos trabalhos</AnchorLink>
        <BsArrowDown />
      </div>
    </Container>
  );
}