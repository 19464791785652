import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { GiHamburgerMenu } from 'react-icons/gi';

import logoImg from '../../assets/logo.svg';

import { Container } from './styles';

export function Header() {
  return (
    <Container>
      <div>
        <h1>
          <img
            src={logoImg} 
            alt="Felipe Ferreira, Web Designer, Front-end, HTML5, CSS3, Wordpress, UX."
          />
        </h1>
        <nav id="nav">
          <ul>
            <li><AnchorLink href="#topo">Home</AnchorLink></li>
            <li><AnchorLink href="#solucoes">Soluções</AnchorLink></li>
            <li><AnchorLink href="#portfolio">Portfólio</AnchorLink></li>
            <li><AnchorLink href="#footer">Contato</AnchorLink></li>
          </ul>
          {/* <GiHamburgerMenu /> */}
        </nav>
      </div>
    </Container>
  )
}